.banner-box{
  width: 100%;
  max-height: 11rem;
}

.banner-box img{
  width: 100%;
  max-height: 11rem;
}

.pg_top_banner {
  background-image: url(../../../assets/images/top_banner_bg.png);
  background-size: contain;
  background-position: center;
}

.pg_top_bg {
  background: rgba(224, 224, 224, 0.9);
  padding: 30px 0 80px 0;
}

.top_banner_img {
  text-align: center;
}

.top_banner_img img {
  width: 100%;
}