:root {
  --kg-green: #128454;
  --kg-white: #ffffff;
  --kg-gray: #dddddd;
  --kg-sky: #f0ffff7a;
  --kg-light: #f4f4f4;
  --kg-dark-gray: #666666;
  --kg-light-dark: #2f3032;
  --kg-dark: #212529;
  --kg-dark-blue: #28394f;
  --kg-red: #e6272c;
  --kg-orange: #f37500;
  --kg-mustard: #ffac5f;
  --kg-font-family: 'Poppins', sans-serif;
  --kg-font-family-bold: "poppins-bold", sans-serif;
  --kg-font-size: 1rem;
}

*,
::after,
::before {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Poppins;
  src: url('./assets/font/poppins_regular.ttf');
}

@font-face {
  font-family: 'poppins-regular';
  src: url('./assets/font/poppins_regular.ttf');
}

@font-face {
  font-family: 'poppins-bold';
  src: url('./assets/font/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'poppins-light';
  src: url('./assets/font/Poppins-Light.ttf');
}

body {
  font-family: var(--kg-font-family) !important;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
q h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul {
  margin: 0;
  padding: 0;
}

a {
  color: var(--kg-dark);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--kg-green);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='radio'],
input[type='checkbox'] {
  cursor: pointer;
}

input[type='radio']+label,
input[type='checkbox']+label {
  cursor: pointer;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-shadow {
  text-shadow: 0px 0.25rem 0.25rem rgb(0 0 0 / 25%);
}

.top-n2 {
  top: -1rem !important;
}

.top-n5 {
  top: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.min-w-fit {
  min-width: fit-content;
}

/** kg background colors **/
.kg-bg-green,
.kg-bg-primary,
.kg-kg-success {
  background-color: var(--kg-green) !important;
}

.kg-bg-dark-gray,
.kg-bg-secondary {
  background-color: var(--kg-dark-gray) !important;
}

.kg-bg-dark-blue {
  background-color: var(--kg-dark-blue) !important;
}

.kg-bg-gray {
  background-color: var(--kg-gray) !important;
}

.kg-bg-sky {
  background-color: var(--kg-sky) !important;
}

.kg-bg-light {
  background-color: var(--kg-light) !important;
}

.kg-bg-light-dark {
  background-color: var(--kg-light-dark) !important;
}

.kg-bg-dark {
  background-color: var(--kg-dark) !important;
}

.kg-bg-yellow,
.kg-bg-warning {
  background-color: var(--kg-orange) !important;
}

.kg-bg-mustard {
  background-color: var(--kg-mustard);
}

.kg-bg-red,
.kg-bg-danger {
  background-color: var(--kg-red) !important;
}

.kg-bg-white {
  background-color: var(--kg-white) !important;
}

/** kg text colors **/
.kg-green,
.kg-primary,
.kg-success {
  color: var(--kg-green) !important;
}

.kg-gray {
  color: var(--kg-gray) !important;
}

.kg-dark-gray,
.kg-secondary {
  color: var(--kg-dark-gray) !important;
}

.kg-yellow,
.kg-warning {
  color: var(--kg-orange) !important;
}

.kg-red,
.kg-danger {
  color: var(--kg-red) !important;
}

.kg-white {
  color: var(--kg-white);
}

.kg-sky {
  color: var(--kg-sky);
}

.kg-light {
  color: var(--kg-light);
}

.kg-light-dark {
  color: var(--kg-light-dark) !important;
}

.kg-dark {
  color: var(--kg-dark) !important;
}

/* kg border colors */
.kg-border-green,
.btn-border-primary,
.kg-border-success {
  border-color: var(--kg-green) !important;
}

.kg-border-gray {
  border-color: var(--kg-gray) !important;
}

.kg-border-dark-gray,
.kg-border-secondary {
  border-color: var(--kg-dark-gray) !important;
}

.kg-border-yellow,
.kg-border-warning {
  border-color: var(--kg-orange) !important;
}

.kg-border-red,
.kg-border-danger {
  border-color: var(--kg-red) !important;
}

.kg-btn {
  text-transform: uppercase;
  font-family: var(--kg-font-family-bold);
}

.kg-btn-rounded {
  text-transform: uppercase;
  font-family: var(--kg-font-family-bold);
  border-radius: 50rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.kg-btn-success,
.kg-btn-green,
.kg-btn-primary {
  color: var(--kg-white) !important;
  background-color: var(--kg-green) !important;
  border-color: var(--kg-green) !important;
}

.kg-btn-secondary,
.kg-btn-gray {
  color: var(--kg-white) !important;
  background-color: var(--kg-dark-gray) !important;
  border-color: var(--kg-dark-gray) !important;
}

.kg-btn-warning,
.kg-btn-yellow {
  color: var(--kg-white) !important;
  background-color: var(--kg-orange) !important;
  border-color: var(--kg-orange) !important;
}

.kg-btn-danger,
.kg-btn-red {
  color: var(--kg-white) !important;
  background-color: var(--kg-red) !important;
  border-color: var(--kg-red) !important;
}

.transition {
  transition: all;
}

.transition-duration-300 {
  transition-duration: 300ms;
}

.transition-duration-500 {
  transition-duration: 500ms;
}

.transition-duration-700 {
  transition-duration: 700ms;
}

.transition-duration-900 {
  transition-duration: 900ms;
}

/**
common css style must be used all over the website
for consitent design and reusability of the css styling
**/
.kg-title-ul {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}

.kg-title-ul::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 30px;
  height: 4px;
  border-radius: 1rem;
  background-color: var(--kg-green);
  transform: translateX(-50%);
}

@media (min-width: 576px) {
  .kg-title-ul::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1rem;
    width: 30px;
    height: 4px;
    border-radius: 1rem;
    background-color: var(--kg-green);
  }
}

@media (min-width: 992px) {
  .kg-lg-white {
    color: var(--kg-gray);
  }

  .kg-lg-white.active {
    color: var(--kg-white) !important;
  }
}

.kg-title {
  text-transform: uppercase;
  font-family: var(--kg-font-family-bold);
}

.line-seprator {
  background-color: var(--kg-white);
  width: 1px;
  height: 20px;
}

.link,
.cursor-pointer {
  cursor: pointer;
}

.link:hover {
  color: var(--kg-green);
}

.link-animate-ul {
  transition: all 0.3s ease-in-out;
}

.border-start-dashed {
  border-right: 1px dashed var(--kg-white);
}

.border-end-dashed {
  border-right: 1px dashed var(--kg-white);
}

.border-top-dashed {
  border-top: 1px dashed var(--kg-white);
}

.border-bottom-dashed {
  border-bottom: 1px dashed var(--kg-white);
}

@media (min-width: 576px) {
  .border-sm-start-dashed {
    border-right: 1px dashed var(--kg-white) !important;
  }

  .border-sm-end-dashed {
    border-right: 1px dashed var(--kg-white) !important;
  }

  .border-sm-top-dashed {
    border-top: 1px dashed var(--kg-white) !important;
  }

  .border-sm-bottom-dashed {
    border-bottom: 1px dashed var(--kg-white) !important;
  }
}

.scale-1 {
  transform: scale(1);
}

.hover-scaleX-1-1:hover {
  transform: scale(1.03);
}

.hover-scaleX-1-2:hover {
  transform: scaleX(1.05);
}

.hover-scaleX-1-3:hover {
  transform: scaleX(1.1);
}

.hover-kg-bg-green:hover {
  background-color: var(--kg-green) !important;
}

.hover-kg-white:hover {
  color: var(--kg-white) !important;
}

.hover-top-n1:hover {
  top: -0.5rem !important;
}

.hover-top-n2:hover {
  top: -1rem !important;
}

.hover-top-n5:hover {
  top: -3rem;
}

.hover-underline:hover{
  text-decoration: underline;
}

/* bootstrap customization */
input.form-control,
select.form-select {
  height: 3rem;
  color: var(--kg-dark-gray);
}

input.form-control-sm, select.form-select-sm{
  height: 2.5rem;
}

li.dropdown-item:active, a.dropdown-item:active {
  background-color: var(--kg-green);
}

.text-word-break {
  word-break: break-word;
}