

#preloader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(31, 31, 31, 0.95);
}
#preloader .active{
  display: block;
}
#preloader .preloader-animation {
  position: absolute;
  top: calc(50% - 3.25em);
  left: calc(50% - 3.25em);
  display: inline-block;
  height: 6.5em;
  width: 6.5em;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  animation: spin 2s linear infinite;
}
#preloader .preloader-animation::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  animation: spin 1.5s linear infinite;
}
#preloader .preloader-animation:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  animation: spin 1s linear infinite;
}
#preloader .preloader-container {
  position: absolute;
  top: calc(50% - 3.25em);
  text-align: center;
  width: 100%;
}

#preloader {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(31, 31, 31, 0.95);
}

#preloader .preloader-animation {
  position: absolute;
  top: calc(50% - 3.25em);
  left: calc(50% - 3.25em);
  display: inline-block;
  height: 6.5em;
  width: 6.5em;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#preloader .preloader-animation::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

#preloader .preloader-animation::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: orange;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

#preloader .preloader-container {
  position: absolute;
  top: calc(50% - 3.25em);
  text-align: center;
  width: 100%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes hide-preloader {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

#preloader .hide-preloader {
  animation: hide-preloader 10s ease-in forwards;  /* Use the hide-preloader animation with a 1-second duration */
}