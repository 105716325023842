.login-popup .modal-dialog {
  width: 407.42px;
  display: block;
}

.login-popup .modal-content {
  box-shadow: 0px 6px 0px rgb(255 255 255 / 18%);
}

.close_modal .btn-close {
  font-size: 0.625rem;
  margin: 0.875rem 1.125rem 0 0;
  opacity: 0.3;
}

.close_modal .btn-close:hover {
  opacity: 1;
}

.login-popup-main h3 {
  font-weight: bold;
  font-size: 27px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #222222;
  margin: 0 0 45px 0;
}

.login-inner button {
  height: 51.57px;
  background: #128454;
  border-radius: 0.875rem;
  border: none;
  width: 100%;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  margin: 1.5rem 0;
  cursor: pointer;
}

.login-inner button:disabled {
  cursor: default;
  filter: grayscale(0.8);
}

.login-bottom {
  background-image: url(../../../assets/images/login_bg.png);
  background-size: 100% 101%;
  padding: 140px 0 35px 0;
  border-radius: 1.5rem;
}

.login-bottom p {
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 2rem;
}

.popup_form input.form-control {
  height: 49px;
}

button.register_modal_btn {
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50px;
  background: none;
  height: 52px;
  width: 204px;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.login-inner {
  padding: 0 48px;
}

.alert_m {
  background: rgba(232, 68, 68, 0.2);
  border-radius: 0.5rem;
  padding: 17px;
  margin: 23px 0;
}