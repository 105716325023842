.blockquote{
  color : #0d6efd !important
}
.laed_head h2 {
  font-family: "poppins-bold";
  font-weight: bold;
  font-size: 50px;
  line-height: 45px;
  text-transform: uppercase;
  color: #424242;
}
.laed_head p {
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  /* text-transform: lowercase; */
  color: #999999;
}
.page_titall {
  /* text-transform: lowercase; */
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: #666666;
  margin: 2.25rem 0 0.875rem 0;
}
.laed_head p span{
  font-size: 20px;
  line-height: 45px;
  color: #999999;
  cursor: pointer;
}

.machine_img_box img {
  border: 1px solid #999999;
  box-sizing: border-box;
  border-radius: 1.25rem;
  height: 132px;
  width: 132px;
  object-fit: cover;
  cursor: pointer;
}

.machine_img_box p {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 27px;
  text-align: center;
  color: #3b393a;
  margin: 0.625rem 0 0 0;
}
