.download-app {
  width: 100%
}

.dowload-app-icon {
  max-width: 175px;
  min-width: 175px;
}

.social-icon {
  transition: transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.2);
}

.social-icon.instagram {
  color: #c13584;
}

.social-icon.twitter {
  color: #1da1f2;
}

.social-icon.youtube {
  color: #ff0000;
}

.social-icon.facebook {
  color: #3b5998;
}