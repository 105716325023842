/************** Header start*******************/
.navbar-brand img{
  width: 6rem;
  max-height: 6rem;
}

@media (min-width: 992px) {
 .navbar-brand img {
  width: 8.5rem;
  max-height: 6.5rem;
 }
}

/* .kg-navbar {
  background: #2f3032 !important;
  color: white;
}
.kg-navbar .container {
  display: flex;
  align-items: center;
  z-index: 99;
}
.kg-navbar .container .logo-holder {
  width: 100%;
  max-width: 136px;
  margin-right: 2rem;
  cursor: pointer;
}
.kg-navbar .container .logo-holder img {
  width: 100%;
}
.kg-navbar .container .nav-container {
  width: 100%;
}
.kg-navbar .container .nav-container li.list-dd {
  position: relative;
}
.kg-navbar .container .nav-container li.list-dd.open .sub-list {
  display: block;
}
.kg-navbar .container .nav-container li.list-dd .sub-list {
  position: absolute;
  display: none;
  background: white;
  color: black;
  top: 2.25rem;
  left: 0;
  padding: 0;
  white-space: nowrap;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
}
.kg-navbar .container .nav-container li.list-dd .sub-list li {
  line-height: 1.25rem;
}
.kg-navbar .container .nav-container li.list-dd .sub-list li::before {
  display: block;
}
.kg-navbar .container .nav-container li.list-dd .sub-list li:hover {
  color: #128454;
}
.kg-navbar .container .nav-container .topnav {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  text-transform: uppercase;
  justify-content: flex-end;
  z-index: 999;
  position: relative;
}
.kg-navbar .container .nav-container .topnav .kg-top-menu {
  cursor: pointer;
}
.kg-navbar .container .nav-container .topnav .location-holder {
  position: relative;
  margin-right: auto;
  display: flex;
  align-items: center;
  transform: scale(1);
  transition: all 0.3s ease;
}
.kg-navbar .container .nav-container .topnav .location-holder:not(.no-under)::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0px;
  background-color: white;
  transform: scale(0);
  transform-origin: 50%;
  transition: all 0.3s ease;
}
.kg-navbar .container .nav-container .topnav .location-holder:hover {
  transform: scale(1.05);
}
.kg-navbar .container .nav-container .topnav .location-holder:hover::after {
  transform: scale(1);
}
.kg-navbar .container .nav-container .topnav .notification-bell {
  font-size: 1.25rem;
}
.kg-navbar .container .nav-container .topnav ul {
  list-style: none;
}
.kg-navbar .container .nav-container .topnav ul.left-list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.kg-navbar .container .nav-container .topnav ul li {
  position: relative;
  padding: 0.5rem 1rem;
  transform: scale(1);
  transition: all 0.3s ease;
  font-size: 1rem;
}
.kg-navbar .container .nav-container .topnav ul li:not(.no-under)::after {
  content: "";
  width: 80%;
  height: 1px;
  position: absolute;
  left: 10%;
  bottom: 8px;
  background-color: white;
  transform: scale(0);
  transform-origin: 50%;
  transition: all 0.3s ease;
}
.kg-navbar .container .nav-container .topnav ul li:hover {
  transform: scale(1.05);
}
.kg-navbar .container .nav-container .topnav ul li:hover::after {
  transform: scale(1);
}
.kg-navbar .container .nav-container .topnav ul li.user-profile-cont {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
}
.kg-navbar .container .nav-container .topnav ul li.user-profile-cont.open .user_profile {
  display: block;
}
.kg-navbar .container .nav-container .topnav ul li.user-profile-cont .user_profile {
  position: absolute;
  display: none;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 0px 0px 0.875rem 0.875rem;
  width: 300px;
  z-index: 2;
  right: -100px;
  top: 50px;
}
.kg-navbar .container .nav-container .topnav ul li.user-profile-cont .user_profile a {
  text-decoration: none;
}
.kg-navbar .container .nav-container .bottomnav {
  background-color: #128454;
  border-top-left-radius: 10px;
  border-top-right-radius: 4px;
  position: relative;
}
.kg-navbar .container .nav-container .bottomnav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0;
}
.kg-navbar .container .nav-container .bottomnav ul li {
  position: relative;
  padding: 0.5rem 0.85rem;
  cursor: pointer;
  font-size: 1rem;
  text-transform: capitalize;
  transform: scale(1);
  transition: all 0.3s ease;
}
.kg-navbar .container .nav-container .bottomnav ul li i {
  margin-left: 0.35rem;
}
.kg-navbar .container .nav-container .bottomnav ul li::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 12px;
  height: 15px;
  border-right: 1px solid #c9c9c9;
}
.kg-navbar .container .nav-container .bottomnav ul li:not(.no-under)::after {
  content: "";
  width: 85%;
  height: 1px;
  position: absolute;
  left: 7%;
  bottom: 8px;
  background-color: white;
  transform: scale(0);
  transform-origin: 50%;
  transition: all 0.3s ease;
}
.kg-navbar .container .nav-container .bottomnav ul li:hover {
  transform: scale(1.05);
}
.kg-navbar .container .nav-container .bottomnav ul li:hover::after {
  transform: scale(1);
}
.kg-navbar .container .nav-container .bottomnav ul li:first-child::before {
  border-right: unset;
} 
.menu_ul {
  display: flex;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
}*/
button {
  box-shadow: none !important;
}
.set_prize .col-md-6 {
  display: flex;
  align-items: center;
}
.rowpad {
  padding-right: calc(var(--bs-gutter-x) * 0.8);
  padding-left: calc(var(--bs-gutter-x) * 0.8);
}

.modal_input {
  width: 100%;
}
/* .menu_nav ul li {
  list-style: none;
  margin: 0 1.5rem;
}
.main_menu {
  position: absolute;
  width: 99%;
  padding: 0 2rem;
}
.main_menu .col-md-10 {
  align-items: center;
  display: flex;
  justify-content: center;
}
.menu_a {
  border: none !important;
}
.menu_nav ul li a {
  font-family: Poppins;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: right;
  color: #ffffff;
  text-decoration: none;
  border-right: 1px solid #ffffff8a;
  padding: 0 1.25rem;
}
.menu_nav {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  background: linear-gradient(58deg, transparent 3%, #128454 0);
  border-radius: 0.5rem 0 0 0;
  z-index: 1;
}
.menu_nav::before {
  content: "";
  height: 53px;
  width: 37%;
  position: absolute;
  right: 0;
  background: #138555;
  z-index: -1;
}

.menu_nav ul li {
  list-style: none;
  margin: 0;
  line-height: 0;
} 
.provide_content h2 {
  letter-spacing: 2px;
  font-size: 2rem;
}
.menu-item {
  position: relative;
}
ul .drop-menu-item a.active {
  border: none;
  font-weight: 700;
  padding-bottom: 0;
}
li.drop-menu-item a {
  color: #0f77a7 !important;
}
li.drop-menu-item {
  line-height: 2.2;
}
.drop-menu-item {
  width: 100%;
  margin: 0 !important;
  text-align: left;
  padding: 0.375rem 1.25rem;
}

.drop-menu-item:hover a {
  color: #138555 !important;
}
.drop-menu-item a {
  color: #555;
}

.menu-item:hover .drop-menu {
  display: block;
}
li.menu-item i {
  font-size: 0.75rem;
  position: relative;
  top: -2px;
}
.dropdown_location i.fas.fa-sort-down {
  top: -3px;
}*/
.mr-1 {
  margin-right: 0.25rem;
}
i.fas.fa-bell {
  font-size: 1.25rem;
  color: white;
}
.bell_count {
  border-radius: 50%;
  background: #e84444;
  position: absolute;
  top: -0.625rem;
  left: 1rem;
  font-size: 0.75rem;
  width: 1.125rem;
  height: 1.125rem;
  z-index: 9;
  line-height: 1.125rem;
  text-align: center;
}
li.bell {
  position: relative;
}
.mega_menu:hover .mega_menu_list {
  display: block;
}
.mega_menu_list .drop-menu {
  left: 130px;
  top: 0.875rem;
  width: 175px;
}
.mega_menu_list li a:hover {
  color: #c7c7c7 !important;
}
.mega_menu_list {
  display: block;
}
.drop-menu {
  display: none;
  position: absolute;
  min-width: 138px;
  left: -0.875rem;
  top: -1.5rem;
  padding: 60px 0 0 0;
  border: 0;
  background-color: #353c40;
  border-radius: 0 !important;
  z-index: 9;
  background: linear-gradient(to bottom, #ffffff00 10%, white 1%);
}
li.drop-menu-item a {
  font-size: 0.75rem !important;
  text-align: left;
  color: #424242 !important;
}
.drop-menu li a:hover {
  border: none !important;
}
.menu_btn {
  background-image: linear-gradient(
    135deg,
    #c39f75 0%,
    rgba(195, 159, 117, 0.95) 25%,
    #a47947 50%,
    #c39f75 75%,
    #8b673d 100%
  );
  padding: 0.75rem 2.2em;
  border: none;
  color: white;
  text-transform: uppercase;
  font-size: 0.875rem;
  transition: 0.5s all;
}
.menu_btn:hover {
  background-image: linear-gradient(
    135deg,
    #8b673d 0%,
    #c39f75 25%,
    #a47947 50%,
    rgba(195, 159, 117, 0.95) 75%,
    #c39f75 100%
  );
  box-shadow: none;
  background-position: 100% 100%;
}
.menu_nav ul li a:hover {
  color: #8ce9e1;
}
header.main_header {
  height: 114px;
  display: flex;
  align-items: center;
  background: #424242;
}
.main_logo img {
  height: 96px;
}
.main_header .col-md-10 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.menu_list {
  display: flex;
  margin: 0.75rem 0;
}
ul.list_view {
  display: flex;
  padding: 0;
  align-items: center;
  margin: 0;
}
.dropdown_location {
  margin-right: 65px;
  display: flex;
  align-items: center;
}
ul.list_view li {
  list-style: none;
  margin: 0 0.875rem;
}
img.home_profile {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  object-fit: cover;
}
ul.list_view li a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.dropdown_location a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.dropdown_location li {
  list-style: none;
}
.dropdown_location i.fas.fa-map-marker-alt {
  margin-right: 0.25rem;
  top: 0;
  font-size: 1rem;
}
.list_view i.fas.fa-mobile-alt {
  font-size: 1rem;
}

.container {
  /* max-width: 1210px !important; */
  width: 100%;
  padding: 0;
}

.dNone {
  display: none;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.text-bold {
  font-family: "poppins-bold";
}

.textGreen {
  color: #128454;
}

.textGrey {
  color: rgba(17, 17, 17, 0.5);
}

.toUpperCase {
  text-transform: uppercase;
}

.green-btn,
.plain-btn {
  display: inline-block;
  background-color: #128454;
  padding: 0.75rem 2.5rem;
  color: white;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}
.green-btn:hover,
.plain-btn:hover {
  color: white;
  background-color: #024328;
}
.green-btn i.fa-chevron-right,
.plain-btn i.fa-chevron-right {
  margin-left: 0.5rem;
}
.green-btn i.fa-chevron-left,
.plain-btn i.fa-chevron-left {
  margin-right: 0.5rem;
}

.plain-btn {
  background-color: white;
  color: black;
  border: 0.5px solid #222222;
}

.more-btn {
  background: rgba(18, 132, 84, 0.2);
  border: 0.5px solid #e0dcdc;
  border-radius: 26px;
  padding: 0.5rem 1.25rem;
}

.flex-jcb-aic {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-jcb-aie {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.flex-jcb-ais {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-jce-aic {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-jcc-aic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-fdc-jcc-aic {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.cursorPointer {
  cursor: pointer;
}

.kgLink {
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.kgLink::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0px;
  background-color: black;
  transform: scale(0);
  transform-origin: 50%;
  transition: all 0.3s ease;
}
.kgLink:hover {
  transform: scale(1.05);
  color: black;
}
.kgLink:hover::after {
  transform: scale(1);
}


.newRadio_button {
  display: flex;
}
.newRadio_button .form-check {
  padding: 0.5rem 1rem 0rem 0.5rem;
}
.newRadio_button .form-check input[type=radio] {
  display: none;
}
.newRadio_button .form-check input[type=radio] + label {
  cursor: pointer;
  position: relative;
  padding-left: 25px;
}
.newRadio_button .form-check input[type=radio] + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid #128454;
}
.newRadio_button .form-check input[type=radio] + label::after {
  content: "";
  display: none;
  left: 3px;
  top: 3px;
  border-radius: 20px;
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: #128454;
  border: none;
}
.newRadio_button .form-check input[type=radio]:checked + label::after {
  display: block;
}

.modal-content {
  border-radius: 1rem;
}



/**********Header End **********/

/* input.form-control {
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 0.875rem;
  height: 52px;
  line-height: 1.25rem;
  color: #999999;
  padding: 0 1.25rem;
}

select.form-control {
  border: 1px solid rgba(34, 34, 34, 0.25);
  box-sizing: border-box;
  border-radius: 0.875rem;
  height: 52px;
  font-size: 17px;
  line-height: 1.25rem;
  color: rgba(34, 34, 34, 0.5);
  padding: 0 1.25rem;
  margin-bottom: 1.25rem;
} */

/******************profile css ********/
.modal-lg,
.modal-xl {
  max-width: 800px;
}

.popup_form .paymen_add label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #222222;
  margin-bottom: 0.625rem;
}

.popup_form input.form-control {
  height: 49px;
}

button.request {
  width: 174px;
  height: 50px;
  background: #128454;
  border: 0.375rem solid #128454;
  box-sizing: border-box;
  border-radius: 90px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: #ffffff;
}
.number_input {
  margin: 0.875rem 0;
  display: flex;
}
.minus_count,
.plus_count {
  text-align: center;
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: rgba(18, 132, 84, 0.1);
  border: 1px solid #dddddd;
  box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 48px;
}
.number_input input {
  height: 48px;
  width: 64px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #dddddd;
  font-size: 1.25rem;
}
.user_p {
  display: flex;
  align-items: center;
  text-decoration: none;
}
#header_name {
  color: white;
  margin-right: 1rem;
}

.nm_user {
  color: black;
  padding: 1.25rem;
}
.nm_user h3 {
  font-weight: bold;
  font-size: 17px;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #222222;
}
.nm_user h3 span {
  font-weight: normal;
  text-transform: lowercase;
  color: #999999;
  display: block;
}
.user_sort {
  padding: 1.25rem;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}
.user_sort p {
  font-size: 0.875rem;
  line-height: normal;
  text-transform: capitalize;
  color: #878787;
  margin: 0;
}
.user_sort p span {
  color: black;
}
.user_link {
  padding: 0.875rem 1.25rem;
}
.user_bttn {
  margin: 0.625rem 0;
}
.user_profile a {
  text-decoration: none;
}
.user_link:hover {
  background: #f5f5f5;
}
.user_link {
  padding: 0.875rem 1.25rem;
  font-size: 0.875rem;
  text-transform: capitalize;
  color: #222222;
}
.user_link i {
  padding-right: 1rem;
  color: #a8a8a8;
}
.log_out_user {
  background: #f4f4f4;
  border-radius: 0px 0px 0.875rem 0.875rem;
  padding: 1.25rem;
  font-weight: bold;
  font-size: 17px;
  line-height: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  color: #222222;
}
.register_text {
  width: 129px;
  height: 45px;
  left: 385px;
  top: 358px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 45px;
  /* identical to box height, or 141% */

  text-transform: capitalize;

  color: #424242;
}

.sub-list {
  position: absolute;
  display: none;
  background: white;
  color: black;
  top: 2.25rem;
  left: 0;
  padding: 0;
  white-space: nowrap;
  border-radius: 3px;
  border: 1px solid #dfdfdf;
}



/* 
ul {
  list-style: none;
}

ul.left-list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

ul li {
  position: relative;
  padding: 0.5rem 1rem;
  transform: scale(1);
  transition: all 0.3s ease;
  font-size: 1rem;
}

ul li:not(.no-under)::after {
  content: "";
  width: 80%;
  height: 1px;
  position: absolute;
  left: 10%;
  bottom: 8px;
  background-color: white;
  transform: scale(0);
  transform-origin: 50%;
  transition: all 0.3s ease;
} */

/* ul li:hover {
  transform: scale(1.05);
} */

/* ul li:hover::after {
  transform: scale(1);
} */
