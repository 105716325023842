input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiFormControl-root {
  position: relative;
  left: -8px;
  width: 404px;
  height: 52px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('./assets/fontawesome/webfonts/fa-brands-400.eot');
  src: url('./assets/fontawesome/webfonts/fa-brands-400.eot?#iefix') format('embedded-opentype'),
    url('./assets/fontawesome/webfonts/fa-brands-400.woff2') format('woff2'),
    url('./assets/fontawesome/webfonts/fa-brands-400.woff') format('woff'),
    url('./assets/fontawesome/webfonts/fa-brands-400.ttf') format('truetype'),
    url('./assets/fontawesome/webfonts/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url('./assets/fontawesome/webfonts/fa-regular-400.eot');
  src: url('./assets/fontawesome/webfonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('./assets/fontawesome/webfonts/fa-regular-400.woff2') format('woff2'),
    url('./assets/fontawesome/webfonts/fa-regular-400.woff') format('woff'),
    url('./assets/fontawesome/webfonts/fa-regular-400.ttf') format('truetype'),
    url('./assets/fontawesome/webfonts/fa-regular-400.svg#fontawesome') format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('./assets/fontawesome/webfonts/fa-solid-900.eot');
  src: url('./assets/fontawesome/webfonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('./assets/fontawesome/webfonts/fa-solid-900.woff2') format('woff2'),
    url('./assets/fontawesome/webfonts/fa-solid-900.woff') format('woff'),
    url('./assets/fontawesome/webfonts/fa-solid-900.ttf') format('truetype'),
    url('./assets/fontawesome/webfonts/fa-solid-900.svg#fontawesome') format('svg');
}

@media only screen and (max-width: 1440px) {
  #home-banner .banner-child .btn-blk .see-more {
    padding: 0.35rem 2rem;
    font-size: 0.875rem;
    bottom: 10%;
  }
  #home-banner .slick-dots li {
    width: auto;
    height: auto;
  }
  #home-banner .slick-dots li button {
    width: 10px;
    height: 10px;
  }
  #home-banner .slick-dots .slick-active button {
    width: 20px;
    height: 20px;
  }
}

/*custom responsive style*/
@media screen and (min-width: 750px) and (max-width: 1000px) {
  .abc_content {
    margin-left: 40px;
  }
  table.mis_table td {
    line-height: normal;
  }
  .set_prize .col-md-6 {
    display: block;
  }
  .full_col .col-md-8,
  .full_col .col-md-4 {
    width: 100%;
  }
  .count_list span {
    font-size: 16px;
  }
  .preed_pay .col-md-8,
  .preed_pay .col-md-4 {
    width: 100%;
  }
  .malik_input .col-md-4,
  .malik_input .col-md-8,
  .malik_input .col-md-6,
  .malik_input .col-md-2,
  .malik_input .col-md-2 {
    display: block;
    margin: 10px 0;
    width: 100% !important;
  }
  form.set_prize {
    margin: 0 20px;
  }
  .malik_input .col-md-2 {
    width: 100%;
  }
  .t-bs {
    text-align: center;
  }
  .set_prize .col-md-6 {
    display: block;
  }
  .domastic h3 {
    font-size: 20px;
  }
  .domastic p {
    font-size: 18px;
    line-height: 20px;
  }
  .offer_frist .col-md-3 {
    width: 50%;
  }
  .service_category {
    width: 100%;
    margin-bottom: 21px;
  }
  .nav-tabs .nav-link {
    height: auto;
    font-size: 14px;
  }
  .domastic_nm {
    font-size: 18px;
    line-height: 34px;
  }
  .laed_head.mb-5 {
    margin-bottom: 10px !important;
  }
  .event_n {
    margin-bottom: 30px;
  }
  .de_img img {
    margin-bottom: 20px;
    height: 150px;
  }
  .event_n button.subscribe {
    font-size: 13px;
    height: 40px;
    width: 105px;
  }
  .event_n input[type='email'] {
    font-size: 13px;
    height: 40px;
  }
  .plan_full .col-md-2 {
    width: 50%;
  }
  .f_plan_btn {
    font-size: 13px;
  }
  .re_form {
    padding: 0;
  }
  .thanks_green {
    height: 336px;
  }
  .data_payment p {
    font-size: 13px;
  }
  .data_payment p span {
    font-size: 15px;
  }
  .agree_c label.form-check-label {
    font-size: 13px;
  }
  .agree_c .add_address {
    top: 10px;
    float: initial;
  }
  .agree_c {
    margin-top: 35px;
    text-align: center;
  }
  .agree_c .form-check .form-check-input {
    float: initial;
  }

  .payment_img img {
    margin-bottom: 10px;
  }
  .payment_details p {
    font-size: 13px;
    line-height: 18px;
  }
  .validtiy {
    font-size: 15px;
  }
  .col-md-4.b-in {
    width: 100%;
    justify-content: flex-end;
    margin: 10px 0;
  }
  .register_main {
    width: 100%;
    margin: auto;
  }
  .dealersh_div img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .dealer_main2 {
    padding: 0px 15px 29px 15px;
  }
  .vision_div .col-md-4,
  .vision_div .col-md-8 {
    width: 100% !important;
  }
  .vision_content h3 {
    font-size: 30px;
    line-height: 36px;
    margin: 12px 0;
  }
  section.customer_storie {
    padding: 30px;
  }
  .cobbine_form {
    padding: 26px 20px 26px 15px;
  }
  .crop_for .col-md-3,
  .crop_for .col-md-4,
  .crop_for .col-md-6 {
    display: block;
    width: 50%;
  }
  .crop_top_div .col-md-7,
  .crop_top_div .col-md-8,
  .crop_top_div .col-md-10 {
    width: 100%;
  }
  .col-md-3.full_100 {
    width: 100%;
  }
  .crop_for .col-md-5,
  .crop_for .col-md-4,
  .crop_for .col-md-2 {
    top: 0;
    left: 0;
    margin-bottom: 15px;
  }
  .cobbine_form .col-md-4,
  .cobbine_form .col-md-5,
  .cobbine_form .col-md-7,
  .cobbine_form .col-md-8 {
    width: 100% !important;
    flex-wrap: wrap;
  }
  .harvester_2 .col-md-4 {
    width: 50%;
  }
  .harvester_2 .laed_head h2 span {
    font-size: 21px;
  }
  .farmer_4 .col-md-4 {
    width: 50%;
  }
  .harvester_accordian button.collapsed {
    font-size: 18px;
  }
  .harvester_accordian .accordion-button {
    font-size: 18px;
  }
  .s-3 {
    margin-top: -12px;
  }

  .red_light_bg a {
    font-size: 13px;
  }
  .red_light_bg i {
    font-size: 15px;
  }
  .laed_head h2 {
    font-size: 30px;
  }
  .col-md-7.mb-4.center_align.justify-end {
    flex-wrap: wrap;
  }
  a.top_link_box {
    font-size: 12px;
    margin-bottom: 10px;
    padding: 8px 14px;
    margin-right: 10px;
  }
  .laed_head h2 span {
    font-size: 25px;
  }
  .machine_main .col-md-3 {
    width: 33.33%;
  }
  .machine_main {
    margin-top: 10px;
  }
  .contect_in .d-flex {
    display: block !important;
  }
  .col-md-6.mt-5.text-right {
    width: 100%;
  }
  .cobbine_form01 .col-md-4,
  .cobbine_form .col-md-4 {
    width: 50%;
    margin-bottom: 14px;
  }
  .acre,
  .locat {
    font-size: 12px;
  }
  .cobbine_form01 .col-md-4 .col-md-4 {
    margin-bottom: 0px;
  }
  .set_prize_table .col-md-2 {
    width: 33.33%;
  }
  .map_card {
    display: none;
  }
  .mobile-nav ul.drop-menu {
    position: initial !important;
    /*display: block;*/
    background: none;
    box-shadow: none;
  }
  .mobile-nav ul.drop-menu li a {
    padding: 7px 0;
    color: white !important;
    font-size: 14px;
    text-align: center;
    text-transform: lowercase;
  }
  .mobile-nav ul.drop-menu li a::first-letter {
    text-transform: uppercase;
  }
  .w_none {
    display: block;
  }
  .m_none {
    display: none;
  }
  .logos ul {
    flex-wrap: wrap;
  }
  header.main_header {
    background: #1dad8a;
  }
  .mobile .header {
    background-image: linear-gradient(to right, #424242, #424242);
    height: 80px;
  }
  .main_logo img {
    height: auto;
    width: 135px;
  }
  main.main_content {
    padding-top: 80px;
  }
  .scan_div {
    text-align: left;
    margin-top: 10px;
  }
  .scan_ p {
    font-family: Poppins;
    font-style: normal;
    font-size: 19px;
    line-height: 28px;
    text-align: left;
    color: #666666;
  }
  section.hello_div,
  section.Services,
  section.register {
    padding: 20px 0;
  }

  .scan_div .row {
    margin: 0;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
    margin-left: calc(var(--bs-gutter-x) * -0.5);
  }
  .p_hello {
    font-size: 18px;
  }
  .hello_name h1 {
    font-size: 25px;
  }
  p.harvester {
    font-size: 15px;
  }
  .scan_ h2 {
    font-size: 16px;
  }
  .scan_ p {
    font-size: 14px;
    line-height: 16px;
  }
  .hello_box h3 {
    font-size: 25px;
    line-height: 45px;
  }
  .hello_box p {
    font-size: 15px;
    line-height: 15.5px;
  }
  .hello_box {
    height: 100px;
  }
  .catagory_tab .nav-tabs .nav-link {
    width: 165px;
    height: 45px;
    font-size: 15px;
    line-height: 30px;
  }
  .tab_des {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #222222;
    margin: 25px 0;
    text-align: center;
  }
  .tab-content .col-md-2 {
    width: 33.33%;
  }
  .tab_card img {
    height: 140px;
  }
  .tab_card {
    border-radius: 12px;
    padding: 8px;
    height: auto;
    margin-bottom: 25px;
  }
  .tab_card p {
    font-size: 16px;
    line-height: 20px;
  }
  .section_head {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 10px;
  }
  p.section_des {
    font-size: 15px;
  }
  .Services .col-md-4 {
    width: 50%;
  }
  .Service_box img {
    width: 100%;
  }
  .Service_box {
    border: 6px solid #ebebeb;
  }
  .Service_box p {
    font-size: 20px;
    margin: 3px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    white-space: initial;
  }
  .register_box {
    height: 100px;
    margin-bottom: 15px;
  }
  .register_box p {
    font-size: 20px;
  }
  .register_box h3 {
    font-size: 20px;
    line-height: 24px;
  }
  p.post {
    font-size: 18px;
    margin: 10px 0 10px 0;
  }
  .offer_zone .owl-carousel .owl-next,
  .offer_zone .owl-carousel .owl-prev {
    width: 32px;
    height: 32px;
    line-height: 32px;
    position: absolute;
    top: -10%;
    font-size: 20px;
    color: #fff;
    text-align: center;
  }
  .offer_zone .owl-carousel .owl-prev {
    right: 36px;
  }
  .owl-dots {
    margin-top: -52px;
  }
  .offer_zone {
    padding: 50px 0 20px 0;
  }
  .offer_zone .owl-dot {
    width: 8px;
    height: 8px;
    padding: 0;
  }
  .offer_zone .owl-dot.active {
    width: 16px;
    height: 16px;
  }
  p.view_all {
    font-size: 15px;
    margin: -16px 0 10px 0;
  }
  .news_bob h3 {
    font-size: 23px;
    line-height: 50px;
    margin: 20px 0 0 0;
  }
  .bottom_footer p {
    margin: 0;
    font-size: 13px;
  }
  .news_bob p {
    font-size: 15px;
    color: white;
    margin: 0;
  }
  .news_bob {
    margin-bottom: 20px;
  }
  .guide button {
    height: 40px;
    font-size: 12px;
    margin-top: 20px;
  }
  footer.footer {
    padding: 22px 0;
  }
  .footer_details {
    margin-bottom: 15px;
  }
  .line_head_footer {
    margin-bottom: 10px;
  }
  .bottom_footer {
    height: 50px;
    line-height: 50px;
    background: #dddddd;
  }
  .links_bottom_footer ul {
    justify-content: center;
    flex-wrap: wrap;
  }
  .links_bottom_footer ul li a {
    font-size: 10px;
    padding: 0 5px;
  }
  ul.list_view {
    padding: 0 50px 0 0;
    justify-content: flex-end;
  }
  button.subscribe {
    font-size: 14px;
    width: 107px;
    height: 41px;
  }
  .menu_top_list {
    width: 100%;
  }
  .head_icon ul {
    flex-wrap: wrap;
  }
  .head_icon ul li {
    margin: 0 15px 10px 0;
  }
}

/* listng  */
.Ad-Zone_Listings {
  /* height: 1100px; */
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #f5f5f5; */
  /* padding-bottom: 120px; */
}

.Ad_Zone_Listings_content {
  height: auto;
  width: 1328px !important;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  position: relative;
  bottom: 50px;
}

.Ad_Zone_Listings_content_top {
  flex: 1;
  display: flex;
}

.Ad_Zone_Listings_content_top_left {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 22px;
}

.Ad_Zone_Listings_content_top_right {
  flex: 6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Ad_Zone_Listings_content_top_right_ul {
  display: flex;
  list-style: none;
  gap: 5px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
}

.Ad_Zone_Listings_content_top_right_ul_li {
  height: 34px;
  width: 100px;
  border-radius: 22px;
  border: 0.5px solid #66666633;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.Ad_Zone_Listings_content_middle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card_box {
  width: 32%;
  margin-bottom: 50px;
  min-height: 430px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.13);
}
.Ad_Zone_Listings_content_middle_card {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  color: #f3f2f2;
  border-color: #ffffff;
  box-shadow: 0px 2px 2px 0px #0000000d;
  display: flex;
  flex-direction: column;

  /* border: 1px solid #f3f2f2; */
}

#Add_more {
  background-color: #f3f2f2;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.Add_more_plus {
  color: #8d8b8b;
  font-size: 25px;
}

.Ad_Zone_Listings_content_middle_card > img {
  border-radius: 12px 12px 0px 0px;
  height: 209px;
  width: 100%;
}

.Ad_Zone_Listings_content_middle_card_img {
  flex: 1;
  border-radius: 10%;
  border-radius: [object Object]px;
}

.Ad_Zone_Listings_content_middle_card_content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Ad_Zone_Listings_content_middle_card_content_top {
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
  align-items: center;
}

.Ad_Zone_Listings_content_middle_card_content_bottom {
  height: 60px;
  display: flex;
  padding: 5px 15px;
  background-color: #f9f6f6;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.13);
}

.Ad_Zone_Listings_content_middle_card_content_bottom_left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  color: #8d8b8b;
}

.Ad_Zone_Listings_content_middle_card_content_bottom_right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #128454;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0px;
}

.Ad_Zone_Listings_content_middle_card_content_top_right {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-direction: column;
  /* flex: 1; */
  gap: 10px;
  /* min-height: 90px; */
  padding-right: 15px;
}

.Validtiy {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  /* text-align: right; */
  color: #11111180;
  text-align: center;
}

.Ad_Zone_Listings_content_middle_card_content_top_left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  /* padding: 10px;
  padding-top: 25px; */
}

.Ad_Zone_Listings_content_middle_card_content_top_left_span1 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 250;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #3a3a3a;
  display: flex;
  gap: 12px;
}

.Ad_Zone_Listings_content_middle_card_content_top_left_span2 {
  font-family: Poppins;
  font-size: 26px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #3a3a3a;
  display: inline-block;
}

.Ad_Zone_Listings_content_middle_card_content_top_left_span3 {
  flex: 1;
  color: #222222;
  font-family: Poppins;
  font-size: 17px;
  font-weight: 700;
  /* line-height: 2px; */
  letter-spacing: 0px;
  text-align: left;
  display: inline-block;
  padding: 10px 10px 0 10px;
}

.Ad_Zone_Listings_content_middle_card_content_top_left_span4 {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: #999999;
}
.add_space {
  min-height: 50px;
  display: block;
}

.Ad_Zone_Listings_content_middle_card_content_top_right_btn {
  height: 39px;
  width: 149px;
  border-radius: 22px;
  background-color: #9999991a;
  color: #222222;
  border: 0.5px solid #66666633;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 10px;
  /* margin-top: 20px; */
}

#boca {
  padding-bottom: 8px;
}

.Ad_Zone_Listings_content_bottom {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
}

#top_banner_img3 {
  width: 64%;
}

.Ad_Zone_Listings_content_top_right_ul_li {
  height: 42px;
  width: 104px;
  border-radius: 22px;
  border: 0.5px solid #66666633;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 22px;
}

.Ad_Zone_Listings_content_bottomss_div1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
}

#Ad_Zone_Listings_content_top_right_ul_li_id {
  width: 125px;
}

#boka {
  padding-bottom: 8px;
}

SELECT {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  /* padding-left: 2rem !important; */
}

a.active.Ad_Zone_Listings_content_top_right_ul_li {
  background: #e4f1e7;
}

.Ad_Zone_Listings_content_top_right_ul_li2 {
  height: 44px;
  width: 100px;
  border-radius: 22px;
  border: 0.5px solid #66666633;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: #e4f1e7;
}

@media screen and (min-width: 790px) and (max-width: 1280px) {
  .menu_nav ul li a {
    font-size: 14px;
    padding: 0 12px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .malik_input .col-md-4,
  .malik_input .col-md-8,
  .malik_input .col-md-6,
  .malik_input .col-md-2,
  .malik_input .col-md-2 {
    display: block;
    margin: 10px 0;
    width: 100% !important;
  }
  form.set_prize {
    margin: 0 20px;
  }
  .malik_input .col-md-2 {
    width: 100%;
  }
  .t-bs {
    text-align: center;
  }
  .set_prize .col-md-6 {
    display: block;
  }
  .menu_nav ul li {
    margin: 0px 6px;
  }
  .menu_nav ul li a {
    font-size: 11px;
    padding: 0 12px;
  }
  .crop_for .col-md-3,
  .crop_for .col-md-4,
  .crop_for .col-md-6 {
    display: block;
    width: 50%;
  }
  .crop_top_div .col-md-7,
  .crop_top_div .col-md-8,
  .crop_top_div .col-md-10 {
    width: 100%;
  }
  .col-md-3.full_100 {
    width: 100%;
  }
  .crop_for .col-md-5,
  .crop_for .col-md-4,
  .crop_for .col-md-2 {
    top: 0;
    left: 0;
    margin-bottom: 15px;
  }
  .dropdown_location {
    margin-right: 30px;
    display: flex;
    align-items: center;
  }
  ul.list_view li a {
    font-size: 11px;
  }
  .scan_ p {
    font-size: 14px;
  }
  .scan_ h2 {
    font-size: 16px;
  }
  .hello_name h1 {
    font-size: 28px;
  }
  .p_hello {
    font-size: 20px;
  }
  p.harvester {
    font-size: 15px;
  }
  .tab-content .col-md-2 {
    width: 33.33%;
    margin-bottom: 24px;
  }
  .links_bottom_footer ul li a {
    font-size: 12px;
  }
  .bottom_footer p {
    font-size: 14px;
  }
}

.container {
  justify-content: flex-start;
}

.right_banner {
  width: 100%;
  height: 100%;
}

.right_banner .img3 {
  border-radius: 4px;
  margin-top: 15px;
}

.react-datepicker__input-container input {
  width: 337px !important;
}

/**temporary requried css*/
.lead_section {
  background: #f9f9f9;
}
.lead_bg {
  background: white;
  border-radius: 0.75rem;
  margin-top: -30px;
}
.lead_bg {
  background: white;
  border-radius: 0.75rem;
  position: relative;
  top: -1.75rem;
  padding: 40px 30px;
}
.laed_head h2 span {
  font-family: "poppins-regular";
  font-weight: normal;
  font-size: 2rem;
  line-height: 45px;
  text-transform: capitalize;
  color: #424242;
}
.laed_head h2 {
  font-family: "poppins-bold";
  font-weight: bold;
  font-size: 50px;
  line-height: 45px;
  text-transform: uppercase;
  color: #424242;
}

/** required css*/
.minusMargin{
  margin-top: -45px !important;
}
