 .home-offerzone {
  background-image: url(../../../../assets/images/new/offer-zone-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.home-offerzone .slick-track{
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.home-offerzone .slick-dots{
  bottom: -3.5rem;
}

.home-offerzone .slick-dots li button {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transform-origin: center;
  background-position: unset;
  transition: none;
}

.home-offerzone .slick-dots li button::before {
  display: none;
}

.home-offerzone .slick-dots .slick-active button {
  background: white;
  width: 14px;
  height: 14px;
}