.tab-btn{
    font-family: inherit;
    color: #000000;
    border: 0.5px solid #66666633;
    font-size: 13px;
}

.tab-btn.active{
    background-color: #e4f1e7;
}

.tab-btn:hover{
    background-color: var(--kg-green);
    color: #ffffff;
}

.tab-btn:focus{
    box-shadow: none;
}